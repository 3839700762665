import React from 'react';
import {Progress} from "reactstrap";
import {map} from "lodash";
import Big from "big.js";

export function RSCBreakdownQuestion({ value }) {
  const { question, complete, incomplete } = value;
  const total = new Big(complete).plus(incomplete);
  const completePercent = total.gt(0) ? new Big(complete).div(total).times(100).round(1) : new Big(0);
  const incompletePercent = completePercent.minus(100).abs();

  return (
    <div className="mb-3 tw-flex tw-flex-col tw-space-y-1">
      <div>
        {question.label}
      </div>
      <Progress multi style={{ height: 28 }}>
        <Progress bar color="success" value={completePercent}>
          {complete} ({completePercent.toString()}%)
        </Progress>
        <Progress bar color="danger" value={incompletePercent}>
          {incomplete} ({incompletePercent.toString()}%)
        </Progress>
      </Progress>
    </div>
  )
}

export function RSCBreakdown({breakdown}) {
  return (
    <>
      {map(breakdown, i => (
        <RSCBreakdownQuestion value={i} key={i.question.key} />
      ))}
    </>
  )
}